<template>
  <v-container>
    <v-row class="title-sticky">
      <v-col cols="12" lg="12">
        <TitleSection
          :title="`${activateTitle[lang]}`"
          :subtitle="`${activateSubTitle[lang]}`"
        ></TitleSection>
      </v-col>
    </v-row>
    <v-form ref="formRestoreAccount" class="py-4" @submit.prevent="restore">
      <v-container>
        <v-row :style="{ justifyContent: 'center' }">
          <!-- DOCUMENT -->
          <v-col v-if="token" cols="12" lg="4" md="4" sm="12">
            <v-row v-if="!success">
              <v-col cols="12" lg="12" md="12" sm="12" class="pb-1 pt-0">
                <v-alert
                  v-if="error.visible"
                  border="left"
                  class="readable"
                  dense
                  :type="error.type"
                >
                  <strong>{{ error[lang] }}</strong>
                </v-alert>
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col cols="12" lg="12" md="12" sm="12" class="pb-1 pt-0">
                <v-alert border="left" class="" dense :type="'success'">
                  <strong>{{
                    successMessage[lang].replace("{names}", payload.nombres)
                  }}</strong>
                </v-alert>
                <p class="text-center">
                  <small>{{ closeMessage[lang] }}</small>
                </p>
              </v-col>
            </v-row>
          </v-col>
          <v-col v-else cols="12" lg="4" md="4" sm="12">
            <v-row>
              <v-alert border="left" class="readable" dense :type="error.type">
                <strong>{{ errorToken[lang] }}</strong>
              </v-alert>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </v-container>
</template>

<script>
import baseConfig from "@/components/forms/baseConfig";
import { mapGetters } from "vuex";
import TitleSection from "@/components/titles/TitleSection.vue";
import { jwtDecode } from "jwt-decode";

export default {
  components: {
    TitleSection,
  },
  data() {
    return {
      ...baseConfig,
      success: false,
      error: {
        type: "error",
        visible: false,
        en: "An error occurred activating your account, please try again later.",
        es: "Ocurrió un error activando tu cuenta, vuelve a intentarlo más tarde.",
      },
      errorToken: {
        en:
          "The URL is invalid or has expired. If the problem persists, request a new account activation URL.",
        es:
          "La URL es inválida o ha caducado. Si el problema persiste solicita una nueva URL de activación de cuenta.",
      },
      successMessage: {
        en: "Dear {names}, your account was successfully activated.",
        es: "Estimado {names}, tu cuenta se activó correctamente.",
      },
      closeMessage: {
        en: "You can now close this window.",
        es: "Ya puedes cerrar esta ventana.",
      },
      greetingMessage: {
        en:
          "Hello <strong>{names}</strong>, enter your new password to reset your account.",
        es:
          "Hola <strong>{names}</strong>, ingresa tu nueva contraseña para restablecer tu cuenta.",
      },
      activateTitle: {
        en: "ACTIVATE ACCOUNT",
        es: "ACTIVAR CUENTA",
      },
      activateSubTitle: {
        en: "",
        es: "",
      },
      loading: false,
      token: "",
      payload: {
        email: "",
        password: "",
        password2: "",
        nombres: "",
        apellidos: "",
        id: 0,
      },
    };
  },
  computed: {
    ...mapGetters({
      lang: "getLanguage",
    }),
  },
  mounted() {
    const { token } = this.$route.query;
    const decoded = jwtDecode(token);

    if (!decoded) return;

    const { data: userData } = decoded;

    if(!userData.IDUSUARIO) return;
    
    this.token = token;
    this.payload = {
      email: userData.CORREO,
      password: "",
      password2: "",
      nombres: userData.NOMBRES,
      apellidos: userData.APELLIDOS,
      id: userData.IDUSUARIO,
    };

    this.activateAccount(this.payload.email, this.payload.id)
  },
  methods: {
    async activateAccount(correo, idUsuario) {
      this.loading = true;
      const response = await this.$store.dispatch("activateAccount", {
        correo,
        idUsuario,
      });
      this.loading = false;
      if(!response) {
        this.error.visible = true;
        return;
      }
      this.success = true;
    },
  },
};
</script>

<style>
.v-alert.readable {
  margin-bottom: 2em;
}
</style>
